import { Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Stack, Text } from '@chakra-ui/react'
import { FiRsUser } from 'design_system/elements/Icons3'
import { NextLink } from 'design_system/utility/NextLink'
import type React from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'

interface IAccountMenuProps {}
interface IColors {
  iconButtonBgColor?: string
  iconButtonColor?: string
  modalTextColor?: string
  modalBgColor?: string
}
interface ICombined extends IColors, IAccountMenuProps {}

export const AccountMenu: React.FC<ICombined> = ({
  iconButtonBgColor = 'secondaryBackground.500',
  iconButtonColor = 'secondaryText.500',
  modalTextColor = 'secondaryText.500',
  modalBgColor = 'secondaryBackground.500',
}) => {
  const { formatMessage } = useIntl()

  const [isOpen, setIsOpen] = useState(false)
  return (
    <Popover isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          onClick={() => { setIsOpen(!isOpen) }}
          bg={iconButtonBgColor}
          color={iconButtonColor}
          rounded="full"
          rightIcon={<FiRsUser color={iconButtonColor} fontSize="xl"/>}
        >
          Login
        </Button>
      </PopoverTrigger>
      <PopoverContent color={modalTextColor} bg={modalBgColor}>
        <PopoverArrow/>
        <PopoverCloseButton onClick={() => { setIsOpen(false) }}/>
        <PopoverHeader px="15px" py="10px" fontSize="lg">{formatMessage({ id: 'login--account' })}</PopoverHeader>
        <PopoverBody p="15px">
          <Stack spacing="5px">
            <NextLink href="/auth/login">
              <Button
                onClick={() => { setIsOpen(false) }}
                w="100%"
                colorScheme="accent"
              >{formatMessage({ id: '--login' })}
              </Button>
            </NextLink>
            <Text textAlign="center" fontWeight="bold">{formatMessage({ id: '--or' })}</Text>
            <NextLink href="/auth/register">
              <Button
                onClick={() => { setIsOpen(false) }}
                w="100%"
              >{formatMessage({ id: '--register' })}
              </Button>
            </NextLink>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
