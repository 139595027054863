import { usePermittedCustomer } from 'helper/usePermittedCustomer'
import { useActiveOrderNavigationQuery } from 'operations/vendure/generated/generated'
import type React from 'react'
import type { INavigationProps } from '../Navigation/Navigation'
import { Navigation } from '../Navigation/Navigation'

export interface INavigationWrapperProps extends INavigationProps { }

export const NavigationWrapper: React.FC<INavigationWrapperProps> = (props) => {
  const { data: activeOrder } = useActiveOrderNavigationQuery()
  const { data: activeUser } = usePermittedCustomer({ redirect: false })

  const itemsInBasket = activeOrder?.activeOrder?.lines.reduce((acc, line) => acc + line.quantity, 0)

  return (
    <Navigation
      // this comes from a development merge and idk whats going on, because that cannot be right, so possibly a merge error
      isLoggedIn={!!activeUser?.activeCustomer}
      navItems={props.navItems}
      navItemsToolbar={props.navItemsToolbar}
      itemsInBasket={itemsInBasket}
    />
  )
}
