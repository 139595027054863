import { Box, Container, Flex, HStack, Spacer } from '@chakra-ui/react'
import type { contactMode, ILinkSecondaryProps } from 'design_system/elements/LinkSecondary/LinkSecondary'
import { LinkSecondary } from 'design_system/elements/LinkSecondary/LinkSecondary'
import type React from 'react'
import { useEffect, useState } from 'react'

export interface IContactLinks {
  href: string
  text: string
  mode?: contactMode
}
interface INavigationToolbarProps {
  contactLinks: ILinkSecondaryProps[]
  navigationLinks?: IMenu[]
  colorText?: string
  colorBackground?: string
}

export const NavigationToolbar: React.FC<INavigationToolbarProps> = ({ contactLinks, navigationLinks, colorText = 'primaryText.650', colorBackground = 'black' }) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    setScrollPosition(window.pageYOffset)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const toolbarHeight = 40
  const breakPoint = 75
  const [toolbarHidden, setToolbarHidden] = useState(false)
  if (toolbarHidden) {
    if (scrollPosition < breakPoint - toolbarHeight) {
      setToolbarHidden(false)
    }
  } else {
    if (scrollPosition > 75) {
      setToolbarHidden(true)
    }
  }

  return (
    <Box
      backgroundColor={colorBackground}
      h={toolbarHidden ? '0px' : `${toolbarHeight}px`}
      overflow="hidden"
      transition="height 0.17s ease-in-out"
    >
      <Container
        maxW="container.page"
        color={colorText}
        py="8px"
        px={6}
        fontSize="md"
      >
        <HStack>
          <HStack spacing={6}>
            {contactLinks.map(link => (
              <LinkSecondary
                key={link.href}
                href={link.href}
                text={link.text}
                mode={link.mode}
              />
            ))}
          </HStack>
          <Spacer/>
          <Box>
            <Flex direction="row">
              <Flex direction="row" gap={6}>
                {navigationLinks?.map(link => (
                  <LinkSecondary
                    key={link.absolutePath}
                    href={link.absolutePath ?? ''} // TODO look over this if it's a good way to handle missing values
                    text={link.title}
                  />
                ))}
              </Flex>
              {/* <CompanySelect/> */}
            </Flex>
          </Box>
        </HStack>
      </Container>
    </Box>
  )
}
