import { Icon } from '@chakra-ui/react'
import type { ILinkSecondaryProps } from 'design_system/elements/LinkSecondary/LinkSecondary'
import { FaInstagramSquare, FaXingSquare } from 'react-icons/fa'
import { GrFacebook } from 'react-icons/gr'
import { IoLogoLinkedin } from 'react-icons/io'
import type { IntlShape } from 'react-intl'

export interface IUrlBuilderItem {
  type: string
  path: string | null
}

interface IProductConfig {
  setFirstVariantAsInitial: boolean
  allowOrderingOutOfStockProducts: boolean
  notInStock: {
    text: string
    color: string
  }
  onlyShowStockStatusWhenLoggedIn: boolean
  disableVariantsWithOneSelectableOption: boolean
}

interface IA11CommerceConfig {
  logo: string
  product: IProductConfig
  urlBuilder: IUrlBuilderItem[]
  showShippingPriceIfFree: boolean
  showOffCanvasCart: boolean
  showPromotionCodeInput: boolean
}

export const A11CommerceConfig: IA11CommerceConfig = {
  logo: '',
  urlBuilder: [
    {
      type: 'ContentPage',
      path: null,
    },
    {
      type: 'content',
      path: null,
    },
    {
      type: 'product',
      path: 'product',
    },
    {
      type: 'news',
      path: 'neuigkeiten',
    },
    {
      type: 'category',
      path: 'category',
    },
    {
      type: 'ProductCategory',
      path: 'category',
    },
  ],
  product: {
    setFirstVariantAsInitial: true,
    disableVariantsWithOneSelectableOption: false,
    allowOrderingOutOfStockProducts: true,
    onlyShowStockStatusWhenLoggedIn: true,
    notInStock: {
      text: 'Versandtermin wird bekannt gegeben',
      color: 'warningColor.500',
    },
  },
  showShippingPriceIfFree: false,
  showOffCanvasCart: false,
  showPromotionCodeInput: false,
}

export function getContactInformation (intl: IntlShape): ILinkSecondaryProps[] {
  return [
    {
      href: `tel:${intl.formatMessage({ id: 'config--mobile-phone-number' }).replace(/\s/g, '')}`,
      text: intl.formatMessage({ id: 'config--mobile-phone-number' }),
      textShort: intl.formatMessage({ id: '--call' }),
      mode: 'mobilephone',
    },
    {
      href: `tel:${intl.formatMessage({ id: 'config--fax-number' }).replace(/\s/g, '')}`,
      text: intl.formatMessage({ id: 'config--fax-number' }),
      textShort: intl.formatMessage({ id: '--fax' }),
      mode: 'fax',
    },
    {
      href: `mailto:${intl.formatMessage({ id: 'config--email-adress' })}`,
      text: intl.formatMessage({ id: 'config--email-adress' }),
      textShort: intl.formatMessage({ id: '--email' }),
      mode: 'email',
    },
  ]
}

export const socialNetworkIcons = [
  {
    href: 'https://www.facebook.com/stockerkamin',
    icon: <Icon as={GrFacebook}/>,
    size: '32px',
  },
  {
    href: 'https://www.instagram.com/stockerkamin/',
    icon: <Icon as={FaInstagramSquare}/>,
    size: '36px',
  },
  {
    href: 'https://www.linkedin.com/company/stocker-kaminsysteme/',
    icon: <Icon as={IoLogoLinkedin}/>,
    size: '42px',
  },
  {
    href: 'https://www.xing.com/pages/h-stockergmbh',
    icon: <Icon as={FaXingSquare}/>,
    size: '36px',
  },
]
