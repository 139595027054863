import type { As } from '@chakra-ui/react'
import { Heading, Text, VStack } from '@chakra-ui/react'
import { NextLink } from 'design_system/utility/NextLink'
import type React from 'react'

export interface IFooterNavigationPanel {
  links: ILink[]
  headline?: string
  titleAs?: As
}
interface IColors {
  colorText?: string
  colorTextDeemphasized?: string
}
interface ICombined extends IColors, IFooterNavigationPanel {}

export const FooterNavigationPanel: React.FC<ICombined> = ({
  links, headline, titleAs,
  colorText = 'primaryText.500', colorTextDeemphasized = 'primaryText.750',
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!links) {
    links = []
  }

  return (
    <VStack
      w={{ base: '100%', md: '312px' }}
      spacing="0"
      color={colorTextDeemphasized}
      align={{ base: 'center', md: 'normal' }}
    >
      <Heading
        color={colorText}
        fontSize={{ base: 'xl', md: '2xl' }}
        mb="8px"
        as={titleAs}
      >
        {headline}
      </Heading>
      {links.map((item, idx) => (item.absolutePath
        ? (
          <NextLink
            key={idx}
            href={item.absolutePath}
            _hover={{ textDecor: 'none', color: colorText }}
            width="fit-content"
          >
            {item.text}
          </NextLink>
          )
        : <Text key={idx}>{item.text}</Text>
      ))}
    </VStack>
  )
}
