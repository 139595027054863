import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react'
import { NextLink } from 'design_system/utility/NextLink'
import type React from 'react'
import { useState } from 'react'

export interface INavigationMenuProps {
  slug: string
  menu: IMenu
}
interface IColor {
  colorText?: string
  colorTextDeemphasized?: string
  colorTextHighlighted?: string
  colorAccent?: string
  colorBackground?: string
  colorBackgroundSecondary?: string
}
interface ICombined extends IColor, INavigationMenuProps {}

export const NavigationMenu: React.FC<ICombined> = ({
  menu, slug,
  colorText = 'primaryText.500',
  colorTextDeemphasized = 'primaryText.650',
  colorTextHighlighted = 'primaryText.800',
  colorAccent = 'accent.500',
  colorBackground = 'primaryBackground.500',
  colorBackgroundSecondary = 'black',
}) => {
  const [activeMenu, setActiveMenu] = useState(0)

  return (
    <Tabs
      orientation="vertical"
      variant="unstyled"
      onChange={setActiveMenu}
      defaultIndex={activeMenu}
      color={colorText}
      index={activeMenu}
    >
      <TabList
        whiteSpace="nowrap"
        alignItems="start"
        bgColor={colorBackground}
        pb="60px"
        pr={menu.linkItems?.[activeMenu].linkItems ? '0' : '40px'}
        pl={menu.linkItems?.[activeMenu].linkItems ? '100vw' : '20px'}
        ml={menu.linkItems?.[activeMenu].linkItems ? '-100vw' : '-20px'}
      >
        {menu.linkItems?.[0] && (
          <NextLink
            href={menu.absolutePath}
            fontFamily="primary"
            px="16px"
            pt="32px"
            pb="8px"
          >{menu.title}
          </NextLink>
        )}
        {menu.linkItems?.map((menuItem, idx) => (
          <Tab
            key={menuItem.title}
            position="relative"
            w="100%"
            onMouseEnter={() => { setActiveMenu(idx) }}
            textAlign="left"
            transition="fontWeight 0.1s"
            fontWeight={idx === activeMenu ? 'bold' : 'normal'}
            bg={(idx === activeMenu && menuItem.linkItems) ? 'black' : ''}
            pr="40px"
            color={slugIsInSubmenu(slug, menuItem.linkItems) ? colorTextDeemphasized : colorText}
          >
            <Box
              position="absolute"
              bgColor={colorAccent}
              h="70%"
              w={idx === activeMenu ? '5px' : '0px'}
              left={0}
              transition="width 0.1s"
            />
            <NextLink
              letterSpacing="0.45px"
              w="100%"
              href={menuItem.absolutePath}
            >{menuItem.alternativeTitle ?? menuItem.title}
            </NextLink>
          </Tab>
        ))}
      </TabList>
      <TabPanels
        bgColor="black"
        w="min-content"
      >
        {menu.linkItems?.map((menuItem) => (
          <TabPanel
            key={menuItem.title}
            p={0}
          >
            {hasSubMenu(menuItem) && (
              <Box
                position="absolute"
                w="calc(50vw + 190px)"
                h="100%"
                bgColor={colorBackgroundSecondary}
              />
            )}
            <VStack
              align="left"
              spacing={0}
            >
              {hasSubMenu(menuItem) && (
                <Text
                  color={colorTextHighlighted}
                  position="relative"
                  fontFamily="primary"
                  px="32px"
                  pt="32px"
                  pb="8px"
                >{menuItem.title}
                </Text>
              )}
              {menuItem.linkItems?.map((subMenuItem) => (
                <NextLink
                  key={subMenuItem.title}
                  href={subMenuItem.absolutePath}
                  p="8px 32px"
                  position="relative"
                  transition="fontWeight 0.1s"
                  color={slug === subMenuItem.absolutePath ? colorTextDeemphasized : colorText}
                  _after={{
                    content: '""',
                    h: '70%',
                    w: 0,
                    bgColor: colorAccent,
                    position: 'absolute',
                    transition: 'width 0.1s',
                    left: '16px',
                  }}
                  _hover={{
                    fontWeight: 'bold',
                    _after: {
                      w: '5px',
                    },
                  }}
                  letterSpacing="1px"
                >{subMenuItem.title}
                </NextLink>
              ))}
            </VStack>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

const hasSubMenu = (tab: IMenu) => {
  return tab.linkItems
}

const slugIsInSubmenu = (
  slug: string,
  subMenu?: IMenu[],
) => subMenu?.some(menuItem => menuItem.absolutePath === slug)
