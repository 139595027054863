import type { BoxProps } from '@chakra-ui/react'
import { Box, Skeleton } from '@chakra-ui/react'
import { asComponent } from 'helper/htmlComponentParser'
import type React from 'react'

export interface ITextProps extends BoxProps {
  text: string
  isLoaded?: boolean
  isNotFulllWidth?: boolean
}

const CustomText: React.FC<ITextProps> = ({ isLoaded = true, text, isNotFulllWidth, ...boxProps }) => {
  return (
    <Box maxW={isNotFulllWidth ? '900px' : ''} mx="auto" {...boxProps}>
      <Skeleton isLoaded={isLoaded} w="fit-content">
        {asComponent(text)}
      </Skeleton>
    </Box>
  )
}

export default CustomText
