import { CheckIcon } from '@chakra-ui/icons'
import { Box, Divider, Heading, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { PriceChange } from 'custom_components/elements/IconsCustom'
import { FiRsCoins } from 'design_system/elements/Icons2'
import { roles } from 'helper/permissions'
import { PriceType, usePriceType } from 'helper/price'
import { useActiveCustomerRolesQuery, useActiveOrderCartQuery, useToogleCustomerSpecificPriceMutation } from 'operations/vendure/generated/generated'
import type React from 'react'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

export const PriceSwitch: React.FC<{ buttonVariant?: boolean }> = ({ buttonVariant }) => {
  const { priceType, persist } = usePriceType()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const intl = useIntl()
  const toast = useToast()
  const { data: activeCustomer } = useActiveCustomerRolesQuery()
  const isPermittedToSwitch = activeCustomer?.activeCustomer?.groups.find((group) => group.name === roles.fullAccess) ?? false

  const queryClient = useQueryClient()

  const tooglePriceMutation = useToogleCustomerSpecificPriceMutation({
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: useActiveCustomerRolesQuery.getKey() })
      queryClient.invalidateQueries({ queryKey: useActiveOrderCartQuery.getKey() })
    },
    onError: error => {
      console.error(error)
    },
  })

  useEffect(() => {
    if (!isPermittedToSwitch) {
      persist(PriceType.Base); return
    }
    persist(activeCustomer?.activeCustomer?.customFields?.useCSP ? PriceType.Partner : PriceType.Base)
  }, [activeCustomer?.activeCustomer?.customFields?.useCSP])

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        {buttonVariant
          ? (
            <IconButton
              rounded="full"
              colorScheme="secondaryBackground"
              aria-label="Preise Button"
              icon={<PriceChange color="secondaryText.500" fontSize="xl"/>}
            />
            )
          : (
            <Text fontWeight="bold" cursor="pointer">
              <FiRsCoins mb="-2px" mr="5px"/>
              {priceType === PriceType.Partner ? intl.formatMessage({ id: 'price-type--partner' }) : intl.formatMessage({ id: 'price-type--customer' })}
            </Text>
            )}
      </PopoverTrigger>
      <Portal>
        <PopoverContent mt="5px">
          <PopoverArrow/>
          <PopoverHeader>
            <Heading as="h4" fontSize="md" fontWeight="bolder">{intl.formatMessage({ id: 'price-type--select' })}</Heading>
          </PopoverHeader>
          <PopoverCloseButton mt="3px"/>
          <PopoverBody padding="0">
            <Box
              padding="10px"
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                persist(PriceType.Partner)
                tooglePriceMutation.mutate({})
                onClose()
              }}
              _hover={{
                background: 'gray.100',
              }}
            >
              <Box width="100%">
                <Heading as="h4" fontSize="sm" fontWeight="bolder" color={priceType === PriceType.Partner ? 'accent.500' : 'black'}>{intl.formatMessage({ id: 'price-type--partner' })}</Heading>
                <Text lineHeight={1.1} fontSize="15px">{intl.formatMessage({ id: 'price-type--partner-desc' })}</Text>
              </Box>
              <Box pr="10px">
                {priceType === PriceType.Partner &&
                  <CheckIcon color="accent.500"/>}
              </Box>
            </Box>
            <Divider/>
            <Box
              padding="10px"
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                persist(PriceType.Base)
                tooglePriceMutation.mutate({})
                onClose()
              }}
              _hover={{
                background: 'gray.100',
              }}
            >
              <Box width="100%">
                <Heading as="h4" fontSize="sm" fontWeight="bolder" color={priceType === PriceType.Base ? 'accent.500' : 'black'}>{intl.formatMessage({ id: 'price-type--customer' })}</Heading>
                <Text lineHeight={1.1} fontSize="15px">{intl.formatMessage({ id: 'price-type--customer-desc' })}</Text>
              </Box>
              <Box pr="10px">
                {priceType === PriceType.Base &&
                  <CheckIcon color="accent.500"/>}
              </Box>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
