import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useActiveCustomerRolesQuery } from 'operations/vendure/generated/generated'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

interface permissionHookConfig {
  routeTo?: string
  permissions?: string[]
  redirect?: boolean
}

/**
 * Hook, which checks if the user is logged in, and optionally if permissions are set checks if he is permitted on this page.
 * This is a "soft" permission system only enforced on the client side currently.
 * @param config - routeTo: route to redirect to if user is not permitted, permissions: array of permissions to check for, redirect: if true, redirects to routeTo if user is not permitted
 * @returns activeCustomer groups
 */
export const usePermittedCustomer = (config: permissionHookConfig | undefined = { routeTo: '/', permissions: [], redirect: true }) => {
  const intl = useIntl()
  const router = useRouter()
  const toast = useToast()

  const activeCustomerRolesQuery = useActiveCustomerRolesQuery()
  const activeCustomer = activeCustomerRolesQuery.data?.activeCustomer

  // check if logged in
  useEffect(() => {
    if (config.redirect && activeCustomerRolesQuery.data && !activeCustomer) {
      toast({
        title: intl.formatMessage({ id: 'toast--please-login' }),
        status: 'warning',
      })
      router.push('/auth/login')
    }
  }, [activeCustomer, intl, config.routeTo, router, toast, activeCustomerRolesQuery.data, config.redirect])

  const permissionsAreRequired = config.permissions ? config.permissions.length > 0 : false
  const userHasRequiredGroup = activeCustomer?.groups.some((group) => config.permissions?.includes(group.name))
  const isPermitted = !permissionsAreRequired || userHasRequiredGroup

  // check permissions
  useEffect(() => {
    // TODO: this currently works because of the simple permission system but needs to be changed if more complex permissions are used
    if (config.redirect && activeCustomer && !isPermitted) {
      toast({
        title: intl.formatMessage({ id: 'toast--insufficient-rights' }),
        status: 'error',
      })
      router.push(config.routeTo ?? '/')
    }
  }, [activeCustomer, intl, isPermitted, config.routeTo, router, toast, config.redirect])

  return { ...activeCustomerRolesQuery, isPermitted: activeCustomer && isPermitted }
}
