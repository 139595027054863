import { Box, Button, Circle, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, IconButton, Stack, useDisclosure } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { A11CommerceConfig } from 'commerce.config'
import { CartPageResponsive } from 'custom_components/pages/CartPage/CartPageResponsive'
import { FiRsShoppingCart, FiRsTrash } from 'design_system/elements/Icons2'
import { FiRsHeart, FiRsUser } from 'design_system/elements/Icons3'
import { NextLink } from 'design_system/utility/NextLink'
import { formatHeadlineColor } from 'helper/formatHeadlineColor'
import { roles } from 'helper/permissions'
import debounce from 'lodash.debounce'
import { useActiveCustomerRolesQuery, useActiveOrderCartQuery, useActiveOrderNavigationQuery, useAdjustOrderLineMutation, useRemoveAllOrderLinesMutation, useRemoveItemFromOrderMutation } from 'operations/vendure/generated/generated'
import type React from 'react'
import { useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { PriceSwitch } from '../PriceSwitch/PriceSwitch'

export interface IAccountMenuLoggedInProps {
  itemsInBasket: number
}

export const AccountMenuLoggedIn: React.FC<IAccountMenuLoggedInProps> = ({ itemsInBasket }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef(null)

  const { data: activeCustomer } = useActiveCustomerRolesQuery()
  const isPermittedToSwitch = activeCustomer?.activeCustomer?.groups.find((group) => group.name === roles.fullAccess) ?? false

  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()

  const { data: activeOrder, refetch: refetchActiveOrder, isLoading: activeOrderLoading } = useActiveOrderCartQuery()

  const { mutate: removeItemFromOrder } = useRemoveItemFromOrderMutation({
    onSuccess: async (data) => {
      console.log('data', data)
      refetchActiveOrder()
      queryClient.invalidateQueries({ queryKey: useActiveOrderNavigationQuery.getKey() })
    },
    onError: (error) => {
      console.log('error', error)
    },
  })

  const { mutate: adjustQuantity, isLoading: adjustQuantityLoading } = useAdjustOrderLineMutation({
    onSuccess: async (data) => {
      refetchActiveOrder()
      queryClient.invalidateQueries({ queryKey: useActiveOrderNavigationQuery.getKey() })
    },
    onError: (error) => {
      console.log('error', error)
    },
  })

  const adjustQuantityDebounced = debounce((id: string, quantity: number) => {
    adjustQuantity({ orderLineId: id, quantity })
  }, 500)

  const preparedProductData = useMemo(() => {
    return activeOrder?.activeOrder?.lines.map((line) => ({
      id: line.id,
      image: {
        fullpath: line.productVariant.assets[0]?.source,
      },
      heading: {
        type: 'p',
        text: formatHeadlineColor(line.productVariant.name),
        size: ['md', 'lg'],
      },
      singlePrice: line.unitPrice,
      linePrice: line.linePrice,
      currentAmount: line.quantity,
      currencyCode: activeOrder.activeOrder?.currencyCode,
      actions: [
        {
          buttonText: 'Entfernen',
          buttonFunction: async () => {
            removeItemFromOrder({ orderLineID: line.id })
          },
          buttonIcon: <FiRsTrash/>,
        },
      ],
      amountAvailable: 999,
      onAmountChange: (e: any) => {
        if (e === 0) return
        adjustQuantityDebounced(line.id, e)
      },
      sku: line.productVariant.sku,
      detailLink: `product/${String(line.productVariant.product.slug)}` + `?sku=${line.productVariant.sku}`,
      facetValues: line.productVariant.facetValues.map((facet) => ({
        facetName: facet.facet.name,
        facetValue: facet.name,
      })),
    }),
    )
  }, [activeOrder?.activeOrder, adjustQuantity, removeItemFromOrder])

  const { mutate: removeAllItemsFromOrder } = useRemoveAllOrderLinesMutation({
    onSuccess: async (data) => {
      refetchActiveOrder()
      queryClient.invalidateQueries({ queryKey: useActiveOrderNavigationQuery.getKey() })
    },
    onError: (error) => {
      console.log('error', error)
    },
  })

  useEffect(() => {
    if (isOpen) {
      refetchActiveOrder()
    }
  })

  return (
    <Stack align="center" direction="row" spacing={4}>
      {isPermittedToSwitch && (
        <PriceSwitch buttonVariant={true}/>
      )}
      <NextLink href="/account/wishlist" position="relative">
        <IconButton
          rounded="full"
          colorScheme="secondaryBackground"
          aria-label="Einkaufswagen Button"
          icon={<FiRsHeart color="secondaryText.500" fontSize="xl"/>}
        />
      </NextLink>
      {!A11CommerceConfig.showOffCanvasCart && (
        <NextLink href="/cart" position="relative">
          <IconButton
            rounded="full"
            colorScheme="secondaryBackground"
            aria-label="Einkaufswagen Button"
            icon={<FiRsShoppingCart color="secondaryText.500" fontSize="xl"/>}
          />
          <Box position="absolute" top="50%" left="50%">
            <Circle
              size={6}
              bgColor="accent.500"
              fontSize={itemsInBasket >= 100 ? 'xs' : 'sm'}
            >
              {itemsInBasket}
            </Circle>
          </Box>
        </NextLink>
      )}
      {A11CommerceConfig.showOffCanvasCart && (
        <>
          <Box position="relative">
            <IconButton
              rounded="full"
              colorScheme="secondaryBackground"
              aria-label="Einkaufswagen Button"
              icon={<FiRsShoppingCart color="secondaryText.500" fontSize="xl"/>}
              ref={btnRef}
              onClick={onOpen}
            />
            <Box position="absolute" top="50%" left="50%">
              <Circle
                size={6}
                bgColor="accent.500"
                fontSize={itemsInBasket >= 100 ? 'xs' : 'sm'}
              >
                {itemsInBasket}
              </Circle>
            </Box>
          </Box>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
            size="lg"
          >
            <DrawerOverlay/>
            <DrawerContent>
              <DrawerCloseButton/>
              <DrawerHeader/>
              <DrawerBody>
                <CartPageResponsive
                  activeOrder={activeOrder?.activeOrder}
                  note={formatMessage({ id: 'cart--note-about-checkout-and-payment' })}
                  preparedProductData={preparedProductData ?? []}
                  buttonProps={{
                    onButtonClick: () => { removeAllItemsFromOrder({}) },
                    buttonText: formatMessage({ id: 'cart--clear-cart' }),
                    buttonColor: 'primaryBackground.300',
                  }}
                  alwaysMobile={true}
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
      <NextLink href="/account">
        <Button
          colorScheme="secondaryBackground"
          textColor="secondaryText.500"
          rounded="full"
          rightIcon={<FiRsUser color="secondaryText.500" fontSize="xl"/>}
        >
          Konto
        </Button>
      </NextLink>
    </Stack>
  )
}
