import { Box, Container, HStack, Spacer } from '@chakra-ui/react'
import type { ILinkSecondaryProps } from 'design_system/elements/LinkSecondary/LinkSecondary'
import { LinkSecondary } from 'design_system/elements/LinkSecondary/LinkSecondary'
import { NextLink } from 'design_system/utility/NextLink'
import NextImage from 'next/image'
import stockerLogo from 'public/img/logo_header.svg'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface ICheckoutNavigationProps {
  logo?: IImage
}

export const CheckoutNavigation: React.FC<ICheckoutNavigationProps> = () => {
  const intl = useIntl()

  const quickbarContactLinks: ILinkSecondaryProps[] = [
    {
      href: `tel:${intl.formatMessage({ id: 'config--mobile-phone-number' })}`,
      text: intl.formatMessage({ id: 'config--mobile-phone-number' }),
      mode: 'mobilephone',
    },
    {
      href: `mailto:${intl.formatMessage({ id: 'config--email-adress' })}`,
      text: intl.formatMessage({ id: 'config--email-adress' }),
      mode: 'email',
    },
  ]
  const quickbarContactLinksMobile: ILinkSecondaryProps[] = [
    {
      href: `tel:${intl.formatMessage({ id: 'config--mobile-phone-number' })}`,
      text: intl.formatMessage({ id: '--call' }),
      mode: 'mobilephone',
    },
    {
      href: `mailto:${intl.formatMessage({ id: 'config--email-adress' })}`,
      text: intl.formatMessage({ id: '--email' }),
      mode: 'email',
    },
  ]

  return (
    <Box
      backgroundColor="primaryBackground.500"
    >
      <Container
        maxW="container.page"
        py="8px"
        fontSize="md"
      >
        <HStack
          h={{ base: '60px', md: '75px', lg: '100px' }}
        >
          <NextLink href="/">
            <Box
              pr={{ base: '0', md: '24px' }}
              width={{ base: '120px', sm: '198px' }}
              mb="auto"
              mt="auto"
            >
              <NextImage
                src={stockerLogo}
                width="150px"
                height="43px"
                layout="responsive"
              />
            </Box>
          </NextLink>
          <Spacer/>
          {/* Show shortened links on mobile */}
          <HStack spacing={6} display={{ base: 'none', md: 'flex' }}>
            {quickbarContactLinks.map(link => (
              <LinkSecondary
                key={link.href}
                href={link.href}
                text={link.text}
                mode={link.mode}
                color="primaryText.500"
              />
            ))}
          </HStack>
          <HStack spacing={6} display={{ base: 'flex', md: 'none' }}>
            {quickbarContactLinksMobile.map(link => (
              <LinkSecondary
                key={link.href}
                href={link.href}
                text={link.text}
                mode={link.mode}
                color="primaryText.500"
              />
            ))}
          </HStack>
        </HStack>
      </Container>
    </Box>
  )
}
