import { Box, ChakraProvider } from '@chakra-ui/react'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { socialNetworkIcons } from 'commerce.config'
import type { IFooterNavigationPanel } from 'custom_components/compounds/FooterNavigationPanel/FooterNavigationPanel'
import { CopyrightNotice } from 'custom_components/modules/CopyrightNotice/CopyrightNotice'
import { Footer } from 'custom_components/modules/Footer/Footer'
import { NavigationWrapper } from 'custom_components/modules/NavigationWrapper/NavigationWrapper'
import { CheckoutNavigation } from 'design_system/modules/CheckoutNavigation/CheckoutNavigation'
import { PriceTypeProvider } from 'helper/price'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import { useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { IntlProvider } from 'react-intl'
import { GlobalStyle } from 'theme/stocker/globals'
import { stockerTheme } from 'theme/stocker/theme'
import '../helper/nprogress.css'
import '../helper/print.css'

// Binding events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient())
  const router = useRouter()

  const navigationElements = pageProps.websiteConfiguration?.getWebsiteConfiguration?.links?.filter((link: any) => link).map((link: any) => ({
    headline: link?.headline ?? undefined,
    links: link?.links?.map((link: any) => {
      const parsedLink: ILink = {
        absolutePath: link?.absolutePath ?? '',
        text: link?.name ?? '',
      }
      return parsedLink
    }),
  }))

  return (
    <IntlProvider locale="de" messages={pageProps.translations} onError={() => {}}>
      <CookiesProvider>
        <Head>
          <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png"/>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png"/>
          <script type="text/javascript"/>
          <script type="text/javascript" src="//geo.cookie-script.com/s/a1a937d465e21027cc0b048871474917.js?region=eu"/>
        </Head>
        <Script id="ybug" strategy="afterInteractive">
          {`
          window.ybug_settings = {"id":"vdy33n124sg9v1z8axhf"};
          var ybug = document.createElement('script'); ybug.type = 'text/javascript'; ybug.async = true;
          ybug.src = 'https://widget.ybug.io/button/'+window.ybug_settings.id+'.js';
          var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ybug, s);
        `}
        </Script>
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
          ga('create', 'UA-96468165-1');
          ga('set', 'anonymizeIp', true);
        `}
        </Script>
        <Script
          src="https://www.google-analytics.com/analytics.js"
          strategy="afterInteractive"
        />
        <PriceTypeProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false}/>
            <Hydrate state={pageProps.dehydratedState}>
              <ChakraProvider theme={stockerTheme}>
                <GlobalStyle/>
                <Box bg={(router.asPath.includes('checkout') && !router.asPath.includes('thank-you')) ? 'gray.100' : 'white'} minH="100vh">
                  {(router.asPath.includes('checkout') && !router.asPath.includes('thank-you'))
                    ? <CheckoutNavigation/>
                    : (<NavigationWrapper navItems={pageProps.navItems} navItemsToolbar={pageProps.navItemsToolbar}/>)}
                  <Component {...pageProps}/>
                  {(router.asPath.includes('checkout') && !router.asPath.includes('thank-you'))
                    ? null
                    : (
                      <>
                        <Footer
                          socialNetworkIcons={socialNetworkIcons}
                          navigationElements={(navigationElements ?? []) as IFooterNavigationPanel[]}
                          contactHtml={pageProps.websiteConfiguration?.getWebsiteConfiguration?.contact ?? ''}
                          newsletterHtml={pageProps.websiteConfiguration?.getWebsiteConfiguration?.newsletter ?? ''}
                        />
                        <CopyrightNotice/>
                      </>
                      )}
                </Box>
              </ChakraProvider>
            </Hydrate>
          </QueryClientProvider>
        </PriceTypeProvider>
      </CookiesProvider>
    </IntlProvider>
  )
}

export default MyApp
