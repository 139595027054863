import type { ButtonProps, CheckboxProps, InputProps, NumberIncrementStepperProps, NumberInputProps, SelectProps } from '@chakra-ui/react'
import type { ReactNode } from 'react'

export interface IFormData {
  formTitle?: string
  blocks: IFormBlock[][]
  gap?: string | { horizontal: string, vertical: string }
}

interface IFormBlock {
  fields: Array<Array<FormData | JsxData>>
  blockTitle?: IHeading
  maxW?: string
}

export abstract class FormData {
  constructor (input: DeepPartial<FormData> & Required<Pick<FormData, 'title' | 'type'>>) {
    Object.assign(this, input)
  }

  public title!: string | ReactNode
  public type!: 'text' | 'email' | 'password' | 'tel' | 'select' | 'button' | 'submit' | 'reset' | 'checkbox' | 'radio' | 'file' | 'number'
  public name?: string
  public grow?: number
  public shrink?: number
  public minWidth?: string
  public defaultValue?: string | boolean | number
  public rightElement?: ReactNode
}

export class InputData extends FormData {
  constructor (input: DeepPartial<InputData> & Required<Pick<InputData, 'title' | 'name' | 'type'>>) {
    super(input)
    Object.assign(this, input)
  }

  public type!: 'text' | 'email' | 'password' | 'tel'
  public inputProps?: InputProps
}

export class SelectData extends FormData {
  constructor (input: DeepPartial<SelectData> & Required<Pick<SelectData, 'title' | 'name' >>) {
    input.type = 'select' as const
    super(input as Required<Pick<SelectData, 'title' | 'name' | 'type'>>)
    Object.assign(this, input)
  }

  public options?: Array<{ value: string, text: string }>
  public inputProps?: SelectProps
}

export class ButtonData extends FormData {
  constructor (input: DeepPartial<ButtonData> & Required<Pick<ButtonData, 'title' | 'type'>>) {
    super(input)
    Object.assign(this, input)
  }

  public align?: 'left' | 'right'
  public type!: 'button' | 'submit' | 'reset'
  public inputProps?: ButtonProps
}

export class JsxData {
  constructor (input?: DeepPartial<JsxData> & Required<Pick<JsxData, 'jsx'>>) {
    Object.assign(this, input)
  }

  public jsx!: JSX.Element
}

export class CheckboxData extends FormData {
  constructor (input: DeepPartial<CheckboxData> & Required<Pick<CheckboxData, 'title' | 'name' >>) {
    input.type = 'checkbox' as const
    input.defaultValue = !!input.defaultValue
    super(input as Required<Pick<CheckboxData, 'title' | 'name' | 'type'>>)
    Object.assign(this, input)
  }

  public inputProps?: CheckboxProps
}
export class AutoCompleteData extends FormData {
  constructor (input: DeepPartial<AutoCompleteData> & Required<Pick<AutoCompleteData, 'title' | 'name' | 'options' >>) {
    input.type = 'text' as const
    input.defaultValue = false
    super(input as Required<Pick<AutoCompleteData, 'title' | 'name' | 'type'>>)
    Object.assign(this, input)
  }

  public options!: Array<{ value: string, label: string }>
  public inputProps?: InputProps
}

export class TextAreaData extends FormData {
  constructor (input: DeepPartial<TextAreaData> & Required<Pick<TextAreaData, 'title' | 'name'>>) {
    input.type = 'text' as const
    super(input as Required<Pick<TextAreaData, 'title' | 'name' | 'type'>>)
    Object.assign(this, input)
  }

  public options!: Array<{ value: string, label: string }>
  public inputProps?: InputProps
}

export class NumberInputData extends FormData {
  constructor (input: DeepPartial<NumberInputData> & Required<Pick<NumberInputData, 'title' | 'name' >>) {
    input.type = 'number' as const
    super(input as Required<Pick<NumberInputData, 'title' | 'name' | 'type'>>)
    Object.assign(this, input)
  }

  public inputProps?: NumberInputProps
  public incrementStepperProps?: NumberIncrementStepperProps
  public decrementStepperProps?: NumberIncrementStepperProps
}

export class IncrementAmountInputData extends FormData {
  constructor (input: DeepPartial<IncrementAmountInputData> & Required<Pick<IncrementAmountInputData, 'title' | 'name' >>) {
    input.type = 'number' as const
    super(input as Required<Pick<IncrementAmountInputData, 'title' | 'name' | 'type'>>)
    Object.assign(this, input)
  }

  public inputProps?: NumberInputProps
  public minValue?: number
  public maxValue?: number
}

export type DeepPartial<T> = {
  [P in keyof T]?:
  | null
  | (T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>)
}
