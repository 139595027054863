import { Box, Circle, Container, HStack, Spacer, Square, Stack, StackDivider, Text, useDisclosure, useOutsideClick, useTheme } from '@chakra-ui/react'
import { getContactInformation } from 'commerce.config'
import { AccountMenu } from 'custom_components/compounds/AccountMenu/AccountMenu'
import { AccountMenuLoggedIn } from 'custom_components/compounds/AccountMenuLoggedIn/AccountMenuLoggedIn'
import { NavigationBar } from 'design_system/compounds/NavigationBar/NavigationBar'
import { NavigationBarMobile } from 'design_system/compounds/NavigationBarMobile/NavigationBarMobile'
import { SearchBar } from 'design_system/compounds/SearchBar/SearchBar'
import { FiRsShoppingCart } from 'design_system/elements/Icons2'
import { FiRsHeart, FiRsSearch, FiRsUser } from 'design_system/elements/Icons3'
import type { ILinkSecondaryProps } from 'design_system/elements/LinkSecondary/LinkSecondary'
import { NextLink } from 'design_system/utility/NextLink'
import { formatHeadlineColor } from 'helper/formatHeadlineColor'
import { buildUrl } from 'helper/urlBuilder'
import { useDebounce } from 'helper/useDebounce'
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import type { NavigationByIdentifierQuery } from 'operations/pimcore/generated/generated'
import type { SearchQuery } from 'operations/vendure/generated/generated'
import { useSearchQuery } from 'operations/vendure/generated/generated'
import stockerLogo from 'public/img/logo_header.svg'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { NavigationToolbar } from '../NavigationToolbar/NavigationToolbar'

export interface INavigationProps {
  navItems?: NavigationByIdentifierQuery
  navItemsToolbar?: NavigationByIdentifierQuery
  isLoggedIn?: boolean
  itemsInBasket?: number
  colorBackground?: string
  colorText?: string
}

export const navbarHeight = {
  mobile: ['60px', '75px'],
  desktop: '100px',
}

export const Navigation: React.FC<INavigationProps> = ({ navItems, navItemsToolbar, itemsInBasket, colorBackground = 'primaryBackground.500', colorText = 'primaryText.500', isLoggedIn = false }) => {
  const intl = useIntl()
  const searchBar = useDisclosure()
  const router = useRouter()

  const slug = useRouter().asPath

  const menus = navItems?.navigationByIdentifier?.linkItems?.map((item) => mapLinkItemsAsMenus(item as INavigationItem))
  const menusToolbar = navItemsToolbar?.navigationByIdentifier?.linkItems?.map((item) => mapLinkItemsAsMenus(item as INavigationItem))

  const theme = useTheme()

  const quickbarContactLinks: ILinkSecondaryProps[] = getContactInformation(intl).filter(item => item.mode !== 'fax')

  const [searchInput, setSearchInput] = useState('')
  const debouncedSearchInput = useDebounce(searchInput, 500)
  const { data, isLoading, fetchStatus, refetch } = useSearchQuery({ input: debouncedSearchInput }, { enabled: false })
  const [searchResults, setSearchResults] = useState<SearchQuery | undefined>()
  const [enterPressed, setEnterPressed] = useState(false)

  useEffect(() => {
    if (debouncedSearchInput.length > 2) {
      refetch()
    }
    setSearchResults(data)
    return () => {
      setSearchResults(undefined)
    }
  }, [data, debouncedSearchInput.length, refetch])

  const ref = useRef(null)
  const [closeSearchResults, setCloseSearchResults] = useState(true)
  useOutsideClick({
    ref,
    handler: () => { setCloseSearchResults(true) },
  })

  useEffect(() => {
    // this useEffect is here in the case that a user presses enter in the search field when it hasnt finished loading
    // as soon as it finished loading it will automatically redirect to the first result
    if ((searchResults?.search.items.length ?? 0) > 0 && enterPressed && !isLoading) {
      setCloseSearchResults(true)
      // Here we use this instead of router.push since router.push messes with the skus of the products in a weird way
      window.location.href = `/product/${String(searchResults?.search.items[0].slug)}?sku=${String(searchResults?.search.items[0].sku)}`
      setEnterPressed(false)
    }
  }, [isLoading, searchResults])

  const searchLogic = (
    <>
      <SearchBar
        // here we check the status of the query since when the query is idle the default for isLoading is true for some reason
        // https://github.com/TanStack/query/issues/3584
        isLoading={(fetchStatus === 'idle' ? false : isLoading)}
        borderRadius={(!closeSearchResults && searchResults) ? '20px' : 'full'}
        borderBottomRadius={(!closeSearchResults && searchResults) ? 0 : undefined}
        w="inherit"
        value={searchInput}
        onChange={e => { setSearchInput(e.target.value) }}
        searchOpen={!closeSearchResults && searchResults && searchResults.search.items.length > 0}
        isInvalid={searchResults?.search.items.length === 0}
        errorBorderColor="failedColor.500"
        onSelect={() => { setCloseSearchResults(false) }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setEnterPressed(true)
            if ((searchResults?.search.items.length ?? 0) > 0) {
              setCloseSearchResults(true)
              setEnterPressed(false)
              // Here we use this instead of router.push since router.push messes with the skus of the products in a weird way
              window.location.href = (`/product/${String(searchResults?.search.items[0].slug)}?sku=${String(searchResults?.search.items[0].sku)}`)
            }
          }
        }}
      />
      {(!closeSearchResults && searchResults && searchResults.search.items.length > 0) && (
        <Stack
          spacing={0}
          border="1px"
          borderTop="none"
          borderColor="gray.300"
          bg="white"
          position="absolute"
          w="inherit"
          divider={<StackDivider borderColor="gray.300"/>}
          maxH="500px"
          overflowY="auto"
          ref={ref}
        >
          {searchResults.search.items.map(item => (
            <Box
              key={item.id}
              color="secondaryText.900"
              _hover={{ bg: 'secondaryBackground.550' }}
              p="10px"
              transition="0.25s"
            >
              <a href={`/product/${item.slug}?sku=${item.sku}`} onClick={() => { setSearchInput('') }}>
                <Stack spacing="0">
                  <Text fontWeight="bold" _hover={{ color: 'accent.500' }} transition="0.25s">
                    {formatHeadlineColor(item.productName)}
                  </Text>
                  <Text color="secondaryText.200">
                    {intl.formatMessage({ id: '--sku' })}: {item.sku}
                  </Text>
                </Stack>
              </a>
            </Box>
          ))}
        </Stack>
      )}
      {(!closeSearchResults && searchResults && searchResults.search.items.length === 0) && (
        <Box
          border="1px"
          borderTop="none"
          borderColor="gray.300"
          bg="white"
          position="absolute"
          w="inherit"
          padding="15px"
        >
          <Text color="failedColor.500">
            {intl.formatMessage({ id: 'search--no-results' })}
          </Text>
        </Box>
      )}
    </>
  )

  return (
    <Box position="sticky" top="0" zIndex="docked" className="not-visible-when-printing">
      <Box
        display={{ base: 'none', lg: 'initial' }}
      >
        <NavigationToolbar navigationLinks={menusToolbar} contactLinks={quickbarContactLinks}/>
      </Box>
      <Box
        backgroundColor={colorBackground}
        top="0"
        w="100%"
        h={{ base: navbarHeight.mobile[0], sm: navbarHeight.mobile[1], lg: navbarHeight.desktop }}
      >
        <Container
          maxW="container.page"
          color={colorText}
          px={{ base: 0, lg: 6 }}
        >
          <Stack spacing={0}>
            <HStack
              w="100%"
              h={{ base: navbarHeight.mobile[0], sm: navbarHeight.mobile[1], lg: navbarHeight.desktop }}
              spacing={0}
              position="relative"
            >
              <HStack
                spacing={{ base: 0, md: 2, xl: 15, '2xl': 20 }}
              >
                <NextLink href="/">
                  <Box
                    pr={6}
                    pl={{ base: 6, lg: 0 }}
                    width={{ base: '108px', sm: '174px' }}
                  >
                    <NextImage
                      src={stockerLogo}
                      width="150px"
                      height="43px"
                      layout="responsive"
                    />
                  </Box>
                </NextLink>
                <Box
                  display={{ base: 'none', lg: 'initial' }}
                >
                  {/* this key probably affects performance negatively and is only here to close the menu when we navigate between pages. */}
                  <NavigationBar key={slug} menus={menus} slug={slug}/>
                </Box>
              </HStack>
              <Spacer/>
              <HStack
                spacing={{ base: 0, xl: 8 }}
                display={{ base: 'inherit', lg: 'none' }}
              >
                <Square onClick={searchBar.onToggle} as="button">
                  <FiRsSearch fontSize="2xl" w="50px"/>
                </Square>
                {isLoggedIn && (
                  <NextLink href="/account/wishlist" position="relative" onClick={searchBar.onClose}>
                    <Square>
                      <FiRsHeart fontSize="2xl" w="50px"/>
                    </Square>
                  </NextLink>
                )}
                {isLoggedIn && (
                  <NextLink href="/cart" position="relative" onClick={searchBar.onClose}>
                    <Square>
                      <FiRsShoppingCart fontSize="2xl" w="50px"/>
                    </Square>
                    {(itemsInBasket !== 0) && itemsInBasket && (
                      <Box position="absolute" top="50%" left="50%">
                        <Circle
                          size={6}
                          bgColor="accent.500"
                          fontSize={itemsInBasket >= 100 ? 'xs' : 'sm'}
                        >
                          {itemsInBasket}
                        </Circle>
                      </Box>
                    )}
                  </NextLink>
                )}
                <NextLink href={isLoggedIn ? '/account' : '/auth/login'} onClick={searchBar.onClose}>
                  <Square>
                    <FiRsUser fontSize="2xl" w="50px"/>
                  </Square>
                </NextLink>
                <Box
                  display={{ base: 'inherit', lg: 'none' }}
                  onClick={searchBar.onClose}
                  pl={4}
                >
                  <NavigationBarMobile
                    menus={menus}
                    navigationLinks={menusToolbar}
                    contactLinks={quickbarContactLinks}
                    slug={slug}
                  />
                </Box>
              </HStack>
              <HStack
                spacing={{ base: 0, lg: 3, xl: 5 }}
                display={{ base: 'none', lg: 'inherit' }}
              >
                <Box w={{ lg: '200px', xl: '300px', '2xl': '350px' }}>
                  {searchLogic}
                </Box>
                {isLoggedIn
                  ? (<AccountMenuLoggedIn itemsInBasket={itemsInBasket ?? 0}/>)
                  : (<AccountMenu/>)}
              </HStack>
              {searchBar.isOpen && (
                <Box
                  position="absolute"
                  left={0}
                  top={navbarHeight.mobile}
                  bgColor={colorBackground}
                  w="100%"
                  h="max-content"
                  py={5}
                  px={7}
                  display={{ base: 'inherit', xl: 'none' }}
                >
                  <Box pos="relative" w="100%">
                    {searchLogic}
                  </Box>
                </Box>
              )}
            </HStack>
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}

interface INavigationItem {
  additionalData?: string
  nameInNavigation?: string
  className: string
  linkItems?: INavigationItem[] | null
  slug: string
  absolutePath: string
  title: string
}

function mapLinkItemsAsMenus (tab: INavigationItem): IMenu {
  const menu: IMenu = {
    title: tab.title,
    alternativeTitle: tab.nameInNavigation,
    absolutePath: tab.className ? buildUrl(tab.className, tab.className === 'ProductCategory' ? tab.slug : tab.absolutePath) : '',
    linkItems: tab.linkItems?.map(mapLinkItemsAsMenus),
  }
  return menu
}
